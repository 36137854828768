export const formatTypes = {
  ddmmyyyy: 0,
  yyyymmdd: 1,
}

/**
 * Format timestamp to DD-MM-YYYY.
 * @param {number} timestamp - Timestamp to be formatted.
 * @return {String} - A string formatted default date to DD-MM-YYYY.
 */

export default function (
  timestamp,
  seperator = '/',
  format = formatTypes.ddmmyyyy,
) {
  const time = new Date(timestamp)
  const day = doublizeNumber(time.getDate())
  const month = doublizeNumber(time.getMonth() + 1)
  const year = time.getFullYear()
  const formattingTypes = {
    [formatTypes.yyyymmdd]: [year, month, day].join(seperator),
    [formatTypes.ddmmyyyy]: [day, month, year].join(seperator),
  }
  return formattingTypes[format]
}

export function getFormattedTime(stringDate) {
  const date = new Date(stringDate)
  const hour = doublizeNumber(date.getUTCHours())
  const minute = doublizeNumber(date.getUTCMinutes())
  const second = doublizeNumber(date.getUTCSeconds())

  return `${hour}:${minute}:${second}`
}
export const getHourinMS = (hour) => hour * 60 * 60 * 1000

//Formats number to double digit i.e. (1 => 01, 7 => 07, 11 => 11)
export const doublizeNumber = (n) => ('0' + n).slice(-2)

export const getMidNightInMS = (timestamp) =>
  new Date(timestamp).setHours(0, 0, 0, 0)

export const dateFormat = 'yyyy-MM-dd'
export const dateWithHourFormat = 'yyyy-MM-dd HH:mm'

import { useState } from 'react'
import DatePicker from 'react-datepicker'
import Button from '../common/Button'
import classes from './RaportGenerator.module.scss'
import { format } from 'date-fns'
import { dateFormat } from 'utils/formatDate'
import { toast } from 'react-toastify'
import { generateReport } from 'api/orders'

const Picker = ({ onChange, selected }) => (
  <div style={{ position: 'relative' }}>
    <DatePicker
      className={classes.DatePicker}
      selected={selected}
      onChange={onChange}
      dateFormat="dd-MM-yyyy"
      onChangeRaw={(e) => {
        e.preventDefault()
      }}
    />
  </div>
)

const RaportGenerator = () => {
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [generateRaportStatus, setGenerateRaportStatus] = useState('init')

  async function onButtonClick() {
    const since = format(startDate, dateFormat)
    const to = format(endDate, dateFormat)
    setGenerateRaportStatus('pending')
    try {
      const res = await generateReport(since, to)
      let csvContent = 'data:text/csv;charset=utf-8,' + res
      var encodedUri = encodeURI(csvContent)
      var link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', `raport-${since}-${to}.csv`)
      document.body.appendChild(link)
      link.click()
      toast('Rozpoczęto pobieranie raportu')
    } catch (err) {
      toast(`Nie udało się wygenerować raportu ${since}-${to}`)
    } finally {
      setGenerateRaportStatus('init')
    }
  }

  return (
    <div>
      <p className={classes.Header}>Raport:</p>
      <div className={classes.DateWrapper}>
        <span>Od</span>
        <span>Do</span>
        <Picker selected={startDate} onChange={(date) => setStartDate(date)} />
        <Picker selected={endDate} onChange={(date) => setEndDate(date)} />
      </div>

      <Button onClick={onButtonClick}>
        {generateRaportStatus === 'init' ? 'Generuj' : 'Generowanie'}
      </Button>
    </div>
  )
}
export default RaportGenerator

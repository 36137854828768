import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import firebase from 'firebase/app';

export default ({ component: C, props: cProps, ...rest }) => {
  const isAuthenticated = firebase.auth().currentUser;
  return (
    <Route
      {...rest}
      render={props =>
        !isAuthenticated
          ? <C {...props} {...cProps} />
          : <Redirect to={`/home`} />
      }
    />)
}

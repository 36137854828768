import { useContext, memo, useState, useEffect } from 'react'

import SectionHeader, { TabButton } from 'components/common/SectionHeader'
import { DetailsContext } from '../index'
import classes from './OrderDetails.module.scss'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import SlideIn from './SlideIn.module.scss'
import DatePicker from 'react-datepicker'
import Button from 'components/common/Button'
import firebase from 'firebase'
import { format } from 'date-fns'
import { dateWithHourFormat } from 'utils/formatDate'
import { toast } from 'react-toastify'
import { parseISO } from 'date-fns/esm'
import { validateOrder } from 'utils/validateOrder'

const maxTextAreaHeight = 90

const DetailsRenderer = ({ order = null }) => {
  if (order === null) {
    return (
      <span style={{ fontSize: '12px' }}>
        Kliknij w zamówienie by wyświetlić jego szczegóły
      </span>
    )
  }

  const {
    company_name = 'Brak nazwy firmy',
    deadline = null,
    post_code = 'Brak kodu pocztowego',
    city = 'Brak miasta',
    address = 'Brak adresu',
    vat_number = '-',
    invoiceDeliveryType = '',
    orgNote = '',
    id = '',
    collectionName = '',
    orderID = '',
  } = order

  const [formStatus, setFormStatus] = useState('init')
  const [invoiceDelivery, setInvoiceDelivery] = useState(invoiceDeliveryType)
  const [date, setDate] = useState(new Date(parseISO(deadline)))
  const [noteInput, setNoteInput] = useState(orgNote)

  function onRadioChange(e) {
    setInvoiceDelivery(e.target.value)
  }

  function onPickerChange(newDate) {
    setDate(newDate)
  }

  function autoGrow(el) {
    if (el.target.scrollHeight < maxTextAreaHeight) {
      el.target.style.height = el.target.scrollHeight + 'px'
    }
  }

  async function onSubmit() {
    try {
      await firebase
        .firestore()
        .collection(collectionName)
        .doc(id)
        .update({
          orgNote: noteInput,
          invoiceDeliveryType: invoiceDelivery,
          deadline: format(date, dateWithHourFormat),
        })
      toast('Zaktualizowano szczegóły zamówienia.')
    } catch (err) {
      toast('Nie udało się zaktualizować szczegółów zamówienia.')
      console.error(err)
    }
  }

  const {
    valid: isOrderValid,
    errorInfo: { errors = [] },
  } = validateOrder(order)

  return (
    <div className={classes.ContentWrapper}>
      <p style={{ marginBottom: '4px' }}>ID zamówienia: {orderID}</p>
      <span className={classes.Info}>{company_name || 'Brak nazwy firmy'}</span>
      <span className={classes.Info}>{address || 'Brak adresu'}</span>
      <span className={classes.Info}>{`${post_code} ${city}`}</span>
      <span className={classes.Info}>NIP: {vat_number}</span>

      {!isOrderValid && (
        <>
          <span style={{ marginTop: '10px' }} className={classes.Error}>
            Uwaga!
          </span>
          <span className={classes.Error}>
            Możliwy brak następujących danych potrzebnych do wygenerowania
            faktury:
          </span>

          <ul style={{ marginLeft: '1rem' }}>
            {errors.map((err, index) => (
              <li key={`ERROR_${index}`}>
                <span className={classes.Error}>{err}</span>
              </li>
            ))}
          </ul>

          <span className={classes.Error}>
            Upewnij się że te dane znajdują się w AMFG.
          </span>
        </>
      )}

      <div className={classes.InputWrapper}>
        <span>Do</span>
        <DatePicker
          className={classes.Input}
          onChangeRaw={(e) => {
            e.preventDefault()
          }}
          onChange={onPickerChange}
          selected={date}
        />
      </div>

      <div className={classes.InputWrapper}>
        <span>Komentarz</span>
        <textarea
          onInput={autoGrow}
          onChange={(e) => setNoteInput(e.target.value)}
          className={classes.Input}
          placeholder="Dodaj notatkę..."
          value={noteInput}
        />
      </div>

      <div className={classes.InputWrapper}>
        <span>Sposób wysyłki faktury</span>
        <div className={classes.RadioWrapper}>
          <div className={classes.RadioButton}>
            <input
              type="radio"
              name="invoiceDeliveryType"
              value="email"
              id="emailDeliveryType"
              checked={invoiceDelivery === 'email'}
              onChange={onRadioChange}
            />
            <label htmlFor="emailDeliveryType">Email</label>
          </div>
          <div className={classes.RadioButton}>
            <input
              type="radio"
              name="invoiceDeliveryType"
              value="parcel"
              id="parcelDeliveryType"
              checked={invoiceDelivery === 'parcel'}
              onChange={onRadioChange}
            />
            <label htmlFor="parcelDeliveryType">Paczka</label>
          </div>
        </div>
      </div>

      <div className={classes.Footer}>
        <Button onClick={onSubmit}>Zapisz</Button>
      </div>
    </div>
  )
}

const withDetailsFromContext = (Component) => (props) => {
  const { order } = useContext(DetailsContext)

  return (
    <SwitchTransition>
      <CSSTransition
        key={order?.id || 'empty'}
        addEndListener={(node, done) =>
          node.addEventListener('transitionend', done, false)
        }
        classNames={SlideIn}
      >
        <Component {...props} order={order} />
      </CSSTransition>
    </SwitchTransition>
  )
}

const Details = withDetailsFromContext(DetailsRenderer)

const OrderDetails = () => {
  return (
    <div className={classes.DetailsWrapper}>
      <SectionHeader>
        <TabButton style={{ paddingLeft: '0px', cursor: 'initial' }}>
          Szczegóły zamówienia
        </TabButton>
      </SectionHeader>
      <Details />
    </div>
  )
}

export default memo(OrderDetails)

import { useState, createContext, useCallback } from 'react'

import Table from './Table'
import OrderDetails from './OrderDetails'
import * as classes from './Orders.module.scss'

export const DetailsContext = createContext({
  order: null,
  openOrder: (order) => {},
  closeOrder: () => {},
})

const Orders = () => {
  const [state, setState] = useState(null)

  const openOrder = useCallback((order, collectionName) => {
    if (!state || state.id !== order.id) {
      setState({ ...order, collectionName })
    }
  }, [])

  const closeOrder = useCallback(() => {
    setState(null)
  }, [])

  return (
    <DetailsContext.Provider value={{ order: state, openOrder, closeOrder }}>
      <div className={classes.MainWrapper}>
        <Table />
        <OrderDetails />
      </div>
    </DetailsContext.Provider>
  )
}

export default Orders

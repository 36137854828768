const keys = ['company_name', 'orderID', 'status', 'created_at', 'deadline']

const headers = [
  'Nazwa firmy',
  'ID zamówienia',
  'Status',
  'Data utworzenia',
  'Deadline',
]
export const generateList = (data) => {
  let results = headers.join(',')

  const rows = data.map((row) => keys.map((key) => row[key] || '').join(','))

  results += '\n'
  results += rows.join('\n')

  return results
}

import { useState, useEffect, useCallback, memo, useRef } from 'react'
import firebase from 'firebase'
import { dateFormat } from 'utils/formatDate'
import { format } from 'date-fns'

export const PageTypes = {
  first: 'first',
  middle: 'middle',
  last: 'last',
}

const ROWS_PER_PAGE = 20

const firstOf = (collection) => collection[0]
const lastOf = (collection) => collection[collection.length - 1]

function throwEmptyPropError(propName) {
  throw `Couldn't find ${propName}`
}

const getFirebaseQueryObject = (
  collectionName = throwEmptyPropError('Collection name is necessary'),
  pagination = {
    currentPage: [],
    direction: 'init',
  },
) => {
  let db = firebase.firestore().collection(collectionName)

  db = db.orderBy('created_at', 'desc')

  switch (pagination.direction) {
    case 'next': {
      const lastElement = lastOf(pagination.currentPage)

      db = db.startAfter(lastElement).limit(ROWS_PER_PAGE)
      break
    }

    case 'prev': {
      const firstElement = firstOf(pagination.currentPage)
      db = db.endBefore(firstElement).limitToLast(ROWS_PER_PAGE)

      break
    }

    case 'init': {
      db = db.limit(ROWS_PER_PAGE)

      break
    }
  }

  return db
}

export const useFirestorePagination = (
  collectionName = throwEmptyPropError('collection name'),
) => {
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [activePageType, setActivePageType] = useState(PageTypes.first)

  const currentPage = useRef([])
  const breakConnection = useRef(null)

  const cleanup = () => {
    breakConnection.current && breakConnection.current()
  }

  const restoreLastPage = () => {
    cleanup()
    breakConnection.current = getFirebaseQueryObject(collectionName, {
      currentPage: currentPage.current,
      direction: 'next',
    }).onSnapshot(loadSnapshot)
  }

  const overrideCurrentData = (snapshot) => {
    currentPage.current = snapshot.docs

    const newData = snapshot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }))

    setData(newData)
  }

  const initialFetch = () => {
    try {
      breakConnection.current = getFirebaseQueryObject(collectionName, {
        currentPage: [],
        direction: 'init',
      }).onSnapshot(loadSnapshot)
      setActivePageType(PageTypes.first)
      isLoading && setLoading(false)
    } catch (err) {
      console.error(err)
    }
  }

  const loadSnapshot = useCallback((snapshot, direction) => {
    const snapshotLength = snapshot.docs.length
    setLoading(false)

    if (!snapshotLength) {
      if (direction === 'next') {
        setActivePageType(PageTypes.last)
        restoreLastPage()
      }
      if (direction === 'prev') {
        setActivePageType(PageTypes.first)
        initialFetch()
      }
      return
    }

    overrideCurrentData(snapshot)
  }, [])

  useEffect(() => {
    initialFetch()
    return cleanup
  }, [])

  const nextPageHandler = async () => {
    try {
      setLoading(true)
      cleanup()

      breakConnection.current = getFirebaseQueryObject(collectionName, {
        currentPage: currentPage.current,
        direction: 'next',
      }).onSnapshot((snapshot) => loadSnapshot(snapshot, 'next'))
      setActivePageType(PageTypes.middle)
    } catch (err) {
      console.error(err)
    }
  }

  const previousPageHandler = async () => {
    try {
      setLoading(true)
      cleanup()
      breakConnection.current = getFirebaseQueryObject(collectionName, {
        currentPage: currentPage.current,
        direction: 'prev',
      }).onSnapshot((snapshot) => loadSnapshot(snapshot, 'prev'))
      setActivePageType(PageTypes.middle)
    } catch (err) {
      console.error(err)
    }
  }

  const goToBeginning = useCallback(() => {
    cleanup()

    initialFetch()
  }, [])

  const goToPrev = useCallback(() => {
    previousPageHandler()
  }, [])

  const goToNext = useCallback(() => {
    nextPageHandler()
  }, [])

  return {
    isLoading,
    activePageType,
    goToBeginning,
    goToPrev,
    goToNext,
    data,
  }
}

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Form, Button, Col, Image } from 'react-bootstrap';

import { signIn } from '../../api/auth';
import blesuLogo from '../../img/logoBlesu.png';
import * as classes from './Auth.module.scss';

export default function () {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  async function handleSubmit(e) {
    e.preventDefault();

    if (!email.trim() || !password.trim()) {
      setError('Please fill the inputs.')
      return;
    }
    if (error) setError('')
    login();
  }

  async function login() {
    try {
      await signIn(email, password)
    }
    catch (error) {
      setError(error)
    }
  }

  return (
    <Container className='pt-5'>
      <Col xs={11} lg={6} className={classes.Wrapper}>
        <div className='text-center mb-3'>
          <Image src={blesuLogo} className='m-auto' />
        </div>
        <Form onSubmit={handleSubmit}>

          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control value={email} onChange={e => setEmail(e.currentTarget.value)} type="email" placeholder="Enter your email..." />
            <Form.Text className="text-muted">
              We'll never share your email with anyone else.
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control value={password} onChange={e => setPassword(e.currentTarget.value)} type="password" placeholder="Enter your password..." />
          </Form.Group>

          {error && <Form.Text className="text-danger mb-2"> {error} </Form.Text>}

          <Button block variant="primary" type="submit">
            Login
          </Button>
          {/* 
          <div className='pt-2 text-center'>
            <Link to='/sign-up'>
              Don't have an account? Sign up
            </Link>
          </div> */}
        </Form>
      </Col>
    </Container>
  )
}
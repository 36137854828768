import pdfMode from 'constants/pdfMode'
import PDFViewer from 'pages/Main/PDFViewer'
import { Modal } from 'react-bootstrap'

import * as classes from './PDFPreviewModal.module.scss'

const PDFPreviewModal = ({ pdfInBase64 = false, pdfUrl = false, onClose }) => {
  const show = !!pdfInBase64 || !!pdfUrl

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      dialogClassName={classes.PdfModalWrapper}
      contentClassName={classes.PdfModalContent}
    >
      <Modal.Header bsPrefix={classes.ModalHeader} closeButton>
        <h4> Podgląd </h4>
      </Modal.Header>
      <Modal.Body>
        <div style={{ height: '100%' }}>
          <PDFViewer mode={pdfMode.base64} data={pdfInBase64} url={pdfUrl} />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default PDFPreviewModal

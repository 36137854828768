import { generateList } from 'utils/generateList'
import { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'

import Button from 'components/common/Button'
import { toast } from 'react-toastify'
const BTN_ID = 'FILTERS_DOWNLOAD_LIST_BUTTON'

const DownloadListButton = ({ data = [] }) => {
  const [buttonState, setButtonState] = useState('init')

  const downloadList = async () => {
    setButtonState('pending')

    try {
      const excel = generateList(data)
      let csvContent = 'data:text/csv;charset=utf-8,' + excel
      var encodedUri = encodeURI(csvContent)
      var link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', `lista.csv`)
      document.body.appendChild(link)
      link.click()

      toast('Rozpoczęto pobieranie listy')
    } catch (err) {
      console.log(err)
      toast('Nie udało się pobrać listy')
    } finally {
      setButtonState('init')
    }
  }

  return (
    <Button onClick={downloadList}>
      {buttonState === 'init' ? 'Pobierz listę' : 'Pobieranie'}
    </Button>
  )
}

export const PortalButton = ({ data = [] }) => {
  const parentRef = useRef()

  useEffect(() => {
    parentRef.current = document.querySelector(`#${BTN_ID}`)
  }, [])

  if (!parentRef.current) {
    return null
  }

  return ReactDOM.createPortal(
    <DownloadListButton data={data} />,
    parentRef.current,
  )
}

import * as yup from 'yup'

function basicTest(field) {
  return !!field
}

let schema = yup.object().shape({
  vat_number: yup.mixed().test('is-vat-number-valid', 'brak NIP', basicTest),
  address: yup.mixed().test('is-address-valid', 'brak adresu', basicTest),
  city: yup.mixed().test('is-city-valid', 'brak miasta', basicTest),
  company_name: yup
    .mixed()
    .test('is-company-name-valid', 'brak nazwy firmy', basicTest),
  post_code: yup
    .mixed()
    .test('is-post-code-valid', 'brak kodu pocztowego', basicTest),
})

export const validateOrder = (data) => {
  try {
    const validation = schema.validateSync(data, { abortEarly: false })

    return { valid: true, errorInfo: {} }
  } catch (err) {
    return { valid: false, errorInfo: err }
  }
}

// export default {
//   apiKey: "AIzaSyC-8SWPt02no5DO1qFWbEzPVwFUz3WhH0g",
//   authDomain: "auto-faktura.firebaseapp.com",
//   databaseURL: "https://auto-faktura.firebaseio.com",
//   projectId: "auto-faktura",
//   storageBucket: "auto-faktura.appspot.com",
//   messagingSenderId: "512029469728",
//   appId: "1:512029469728:web:4b4c3eedd52645b8756045",
//   measurementId: "G-ZTLV4GE8DY"
// };

export default {
  apiKey: 'AIzaSyC-8SWPt02no5DO1qFWbEzPVwFUz3WhH0g',
  authDomain: 'auto-faktura.firebaseapp.com',
  databaseURL: 'https://auto-faktura.firebaseio.com',
  projectId: 'auto-faktura',
  storageBucket: 'auto-faktura.appspot.com',
  messagingSenderId: '512029469728',
  appId: '1:512029469728:web:7460b4360f1d9702756045',
  measurementId: 'G-DHF2EZM3RS',
}

/*eslint no-useless-catch: "off"*/

import Axios from 'axios'
import firebase from 'firebase'

const api = 'https://europe-west1-auto-faktura.cloudfunctions.net'

const urlWrapper = (endpoint) => `${api}${endpoint}`

export async function getOrders(
  filters,
  abortController = new AbortController(),
) {
  try {
    const params = new URLSearchParams(filters)
    const url = new URL(urlWrapper('/get'))
    url.search = params.toString()

    const res = await fetch(url.toString(), {
      method: 'GET',
      signal: abortController.signal,
    }).then((res) => res.json())

    return res.data
  } catch (error) {
    throw error
  }
}

export async function cancelWaybill(docId, deliveryService, packageId) {
  try {
    const res = await Axios.get(urlWrapper('/cancelWaybill'), {
      params: { docId, deliveryService, packageId },
    })
    return res.data
  } catch (error) {
    throw error
  }
}

export async function generateReport(since, to) {
  try {
    const res = await Axios.get(urlWrapper('/getRaport'), {
      params: { since, to },
    })
    return res.data
  } catch (error) {
    throw error.message
  }
}

export async function downloadInvoice(id) {
  try {
    const res = await Axios.get(urlWrapper('/getInvoicePDFProxy'), {
      params: { id },
    })

    return res
  } catch (err) {
    throw err
  }
}

// export async function CancelApaczka(orderID) {
//   try {
//     // return Axios.get(`https://europe-west1-auto-faktura.cloudfunctions.net/cancel_apaczka?id=${orderID}`);
//   } catch (error) {
//     throw error
//   }
// }

// export async function CancelUPS(orderID) {
//   try {
//     // return Axios.get(`https://europe-west1-auto-faktura.cloudfunctions.net/cancel_ups?id=${orderID}`);
//   } catch (error) {
//     throw error
//   }
// }

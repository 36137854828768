import React from 'react'

import Navbar from '../../components/Navbar'

import * as classes from './Main.module.scss'
import Orders from './Orders'

function App() {
  return (
    <>
      <Navbar />
      <main className={classes.Container}>
        <h1>Zamówienia</h1>

        <section
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            overflow: 'hidden',
            width: '100%',
            height: '100%',
            paddingBottom: '30px',
          }}
        >
          <Orders />
        </section>
      </main>
    </>
  )
}

function AppProvider({ children }) {}

export default App

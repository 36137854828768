import firebase from 'firebase'
const safetySwitch = 'safetySwitch'

export async function getStatus() {
  try {
    const snapshot = await firebase
      .firestore()
      .collection(safetySwitch)
      .doc('0')
      .get()
    return snapshot.data()
  } catch (error) {
    throw error.message
  }
}

export async function updateStatus(data) {
  try {
    return await firebase
      .firestore()
      .collection(safetySwitch)
      .doc('0')
      .update(data)
  } catch (error) {
    throw error.message
  }
}

import fbConfig from './fbConfig';
import firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/firestore';

export function initFirebase() {
  firebase.initializeApp(fbConfig);
  firebase.firestore();
  firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
}


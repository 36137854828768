import React, { useCallback, useContext, memo, useState } from 'react'
import classes from '../Results.module.scss'
import Switch from 'components/common/Switch'

import firebase from 'firebase'
import { toast } from 'react-toastify'
import { PageTypes, useFirestorePagination } from 'hooks/useFirebasePagination'
import { withOpenOrderAction } from 'hocs/withOpenOrderAction'
import { FiltersContext, anyFiltersSelected } from 'context/filtersContext'
import {
  DeleteIcon,
  DownloadIcon,
  GenerateIcon,
  ListIcon,
  PreviewIcon,
  WarningIcon,
} from '../Icons'
import { parseISO } from 'date-fns'
import PDFPreviewModal from 'components/PDFPreviewModal'
import { useCustomPagination } from 'hooks/useCustomPagination'
import { PortalButton } from 'components/common/DownloadListButton'

import clsx from 'clsx'
import { validateOrder } from 'utils/validateOrder'
import { withSelectedOrder } from 'hocs/withSelectedOrder'

const withFilters = (Component) => (props) => {
  const { filters } = useContext(FiltersContext)

  return <Component {...props} filters={filters} />
}

const combinedContextConsumers = (Component) =>
  withOpenOrderAction(withFilters(Component))

function throwEmptyPropError(propName) {
  throw `Couldn't find ${propName}`
}
const WarningCell = () => (
  <div
    style={{
      position: 'absolute',
      zIndex: '1000',
      width: '16px',
      height: '18px',
      top: '-2px',
      right: '-19px',
    }}
  >
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.07826 14.3182V17.1818C6.07826 17.4034 5.98573 17.5952 5.80066 17.7571C5.6156 17.919 5.39644 18 5.14319 18H1.40293C1.14969 18 0.930532 17.919 0.745467 17.7571C0.560402 17.5952 0.46787 17.4034 0.46787 17.1818V14.3182C0.46787 14.0966 0.560402 13.9048 0.745467 13.7429C0.930532 13.581 1.14969 13.5 1.40293 13.5H5.14319C5.39644 13.5 5.6156 13.581 5.80066 13.7429C5.98573 13.9048 6.07826 14.0966 6.07826 14.3182ZM6.51657 0.818182L6.10748 10.6364C6.09774 10.858 5.9979 11.0497 5.80797 11.2116C5.61803 11.3736 5.39644 11.4545 5.14319 11.4545H1.40293C1.14969 11.4545 0.928097 11.3736 0.738162 11.2116C0.548227 11.0497 0.448389 10.858 0.438649 10.6364L0.0295578 0.818182C0.0198176 0.596591 0.105045 0.40483 0.28524 0.242898C0.465435 0.0809659 0.682155 0 0.935402 0H5.61073C5.86397 0 6.08069 0.0809659 6.26089 0.242898C6.44108 0.40483 6.52631 0.596591 6.51657 0.818182Z"
        fill="#FF0606"
      />
      <path
        d="M15.0783 14.3182V17.1818C15.0783 17.4034 14.9857 17.5952 14.8007 17.7571C14.6156 17.919 14.3964 18 14.1432 18H10.4029C10.1497 18 9.93053 17.919 9.74547 17.7571C9.5604 17.5952 9.46787 17.4034 9.46787 17.1818V14.3182C9.46787 14.0966 9.5604 13.9048 9.74547 13.7429C9.93053 13.581 10.1497 13.5 10.4029 13.5H14.1432C14.3964 13.5 14.6156 13.581 14.8007 13.7429C14.9857 13.9048 15.0783 14.0966 15.0783 14.3182ZM15.5166 0.818182L15.1075 10.6364C15.0977 10.858 14.9979 11.0497 14.808 11.2116C14.618 11.3736 14.3964 11.4545 14.1432 11.4545H10.4029C10.1497 11.4545 9.9281 11.3736 9.73816 11.2116C9.54823 11.0497 9.44839 10.858 9.43865 10.6364L9.02956 0.818182C9.01982 0.596591 9.10504 0.40483 9.28524 0.242898C9.46543 0.0809659 9.68216 0 9.9354 0H14.6107C14.864 0 15.0807 0.0809659 15.2609 0.242898C15.4411 0.40483 15.5263 0.596591 15.5166 0.818182Z"
        fill="#FF0606"
      />
    </svg>
  </div>
)

function toastSuccess(turnedOn, orderID) {
  const text = `${
    turnedOn ? 'Włączono' : 'Wyłączono'
  } generowanie faktury dla zamówienia ${orderID}`
  toast(text)
}

function toastFail(orderID) {
  const text = `Nie udało się zmienić statusu generowania faktury dla zamówienia ${orderID}`
  toast(text)
}

const WaybillRenderer = ({ waybill, docId }) => {
  const trackingNumber =
    waybill.UpsTrackingNumber || waybill.ApaczkaTrackingNumber || ''
  const label =
    trackingNumber ||
    (waybill.personalPickup === true && 'Odbiór osobisty') ||
    'Brak'

  const [pdfVal, setPdf] = useState(false)

  function openPDF() {
    if (waybill.checkListPdfBase64) {
      setPdf(waybill.checkListPdfBase64)
    }
  }

  function closePDF() {
    setPdf(false)
  }

  function downloadClick() {
    if (waybill.checkListPdfBase64) {
      const linkSource = `data:application/pdf;base64,${waybill.checkListPdfBase64}`
      const downloadLink = document.createElement('a')
      const fileName = `list_przewozowy.pdf`
      downloadLink.href = linkSource
      downloadLink.download = fileName
      downloadLink.click()
    }
  }

  function openListPreview() {
    setPdf(waybill.checkListPdfBase64)
  }

  async function onCancelClick() {
    try {
      const { packageId, deliveryService } = waybill

      if (!(packageId && docId && deliveryService)) {
        throw 'Missing properties'
      }

      const res = await cancelWaybill(docId, deliveryService, packageId)

      toast('Anulowano list przewozowy')
    } catch (err) {
      toast('Nie udało się anulować listu przewozowego')
    }
  }

  console.log(waybill)
  return (
    <tr>
      <PDFPreviewModal pdfInBase64={pdfVal} onClose={closePDF} />
      <td>{label}</td>
      <td className={classes.SwitchCell}>
        {waybill.waybillString ? (
          <DownloadIcon onClick={downloadClick} />
        ) : null}
      </td>
      <td className={classes.SwitchCell}>
        {waybill.waybillString ? <PreviewIcon onClick={openPDF} /> : null}
      </td>
      <td className={classes.SwitchCell}>
        {waybill.checkListPdfBase64 ? (
          <ListIcon onClick={openListPreview} />
        ) : null}
      </td>
      <td className={classes.SwitchCell}>
        {waybill.status === 'ok' && waybill.deliveryService ? (
          <DeleteIcon onClick={onCancelClick} />
        ) : null}
      </td>
    </tr>
  )
}

const ErrorWaybill = ({}) => {
  return (
    <tr>
      <td>Błąd przy generowaniu listu przewozowego</td>
    </tr>
  )
}

const RowRenderer = memo(
  ({
    data = {},
    invoiceSwitchHandler = () => {},
    openOrder = () => {},
    isSelected = false,
  }) => {
    const {
      company_name = '',
      orderID = '',
      status = 'new',
      created_at = '-',
      deadline = false,
      generateInvoice = false,
      id = '',
      waybills = [],
      vat_number = '',
    } = data

    const switchHandler = useCallback(() => {
      invoiceSwitchHandler(id, !generateInvoice, orderID)
    }, [generateInvoice])

    function onOrderClick() {
      openOrder(data, 'newOrders')
    }
    const [opened, setOpened] = useState(false)
    function onExpandClick(e) {
      e.stopPropagation()
      setOpened(!opened)
    }

    const parsedDeadline = deadline
      ? new Date(parseISO(deadline))?.getTime()
      : 0

    const deadlineDate = new Date(Date.now())
    deadlineDate.setHours(0, 0, 0, 0)
    const isAfterDeadline = deadlineDate.getTime() - parsedDeadline > 0

    const { valid: isValid } = validateOrder(data)

    const filteredWaybills = waybills.filter((waybill) => !!waybill)

    const selectedBackgroundColor = isSelected
      ? { backgroundColor: 'rgb(226 226 226 / 52%)' }
      : {}
    return (
      <React.Fragment>
        <tr
          onClick={onOrderClick}
          style={{ cursor: 'pointer', ...selectedBackgroundColor }}
        >
          <td>
            <div
              className={
                opened ? classes.ExpandButtonActive : classes.ExpandButton
              }
              onClick={onExpandClick}
            ></div>
          </td>
          <td className={classes.AlertCell}>
            <span>{company_name}</span>
            {!isValid && <WarningIcon className={classes.AlertIcon} />}
          </td>
          <td>{orderID}</td>
          <td>{status}</td>
          <td>{created_at}</td>
          <td style={{ overflow: 'visible' }}>
            <span style={{ position: 'relative' }}>
              {deadline
                ? new Date(parseISO(deadline)).toLocaleDateString()
                : '-'}
              {isAfterDeadline ? <WarningCell /> : null}
            </span>
          </td>
          <td className={classes.SwitchCell}>
            <Switch checked={generateInvoice} onClick={switchHandler} />
          </td>
        </tr>
        {opened ? (
          <tr className={classes.MoreInfo}>
            <td>
              <table className={classes.NestedTable}>
                <thead>
                  <tr>
                    <th>Numer listu</th>
                    <th className={classes.SwitchCell}>Pobierz</th>
                    <th className={classes.SwitchCell}>Podgląd</th>
                    <th className={classes.SwitchCell}>Lista</th>
                    <th className={classes.SwitchCell}>Anuluj</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredWaybills && filteredWaybills.length ? (
                    filteredWaybills.map((waybill) =>
                      filteredWaybills.status === 'ok' ? (
                        <WaybillRenderer
                          key={waybill.packageId || 'Personal_pickup_'}
                          waybill={waybill}
                          docId={id}
                        />
                      ) : (
                        <ErrorWaybill
                          key={waybill.packageId || 'Personal_pickup_'}
                        />
                      ),
                    )
                  ) : (
                    <tr style={{ paddingTop: '15px' }}>
                      <td>Brak listów przewozowych</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </td>
          </tr>
        ) : null}
      </React.Fragment>
    )
  },
)

const FilteredRenderer = ({ filters, openOrder }) => {
  const pagination = useCustomPagination('newOrders', filters)

  return (
    <Renderer
      openOrder={openOrder}
      pagination={pagination}
      hidePagination={true}
    />
  )
}

const RealTimeRenderer = ({ filters, openOrder }) => {
  const pagination = useFirestorePagination('newOrders')

  return <Renderer openOrder={openOrder} pagination={pagination} />
}

const Renderer = withSelectedOrder(
  memo(
    ({
      openOrder = () => {},
      pagination,
      hidePagination = false,
      selectedOrder = null,
    }) => {
      const {
        isLoading,
        activePageType,
        goToNext,
        goToPrev,
        goToBeginning,
        data = [],
      } = pagination

      const { id: selectedId = '' } = selectedOrder || {}

      async function invoiceSwitchHandler(documentID, value, orderID) {
        try {
          await firebase
            .firestore()
            .collection('newOrders')
            .doc(documentID)
            .update({ generateInvoice: value })

          toastSuccess(value, orderID)
        } catch (err) {
          console.error(err)
          toastFail(orderID)
        }
      }

      const isFirstPage = activePageType === PageTypes.first
      const isLastPage = activePageType === PageTypes.last
      return (
        <div className={classes.TableWrapper}>
          <PortalButton data={data} />
          {isLoading ? (
            <div className={classes.Loader}>
              <h5>Ładowanie</h5>
            </div>
          ) : null}
          <div
            style={{
              overflowY: 'scroll',
            }}
          >
            <table className={classes.NewOrdersTable}>
              <thead>
                <tr>
                  <th></th>
                  <th>Nazwa firmy</th>
                  <th>ID</th>
                  <th>Status</th>
                  <th>Data utworzenia</th>
                  <th>Deadline</th>
                  <th className={classes.SwitchCell}>Generowanie faktury</th>
                </tr>
              </thead>
              <tbody>
                {data.map((row) => (
                  <RowRenderer
                    key={`New_order_${row.id}`}
                    data={row}
                    invoiceSwitchHandler={invoiceSwitchHandler}
                    openOrder={openOrder}
                    isSelected={row.id === selectedId}
                  />
                ))}
              </tbody>
            </table>
          </div>
          <div className={classes.Pagination}>
            <button disabled={isFirstPage} onClick={goToBeginning}>
              Najnowsze
            </button>
            <button disabled={isFirstPage} onClick={goToPrev}>
              Poprzednie
            </button>
            <button disabled={isLastPage} onClick={goToNext}>
              Następne
            </button>
          </div>
        </div>
      )
    },
  ),
)

const NewRenderer = memo(({ openOrder, filters }) => {
  const showFilteredList = anyFiltersSelected(filters)

  return showFilteredList ? (
    <FilteredRenderer filters={filters} openOrder={openOrder} />
  ) : (
    <RealTimeRenderer filters={filters} openOrder={openOrder} />
  )
})

export default combinedContextConsumers(NewRenderer)

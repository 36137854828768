import firebase from 'firebase/app'

export async function signIn(email, password) {
  try {
    return await firebase.auth().signInWithEmailAndPassword(email, password)
  } catch (error) {
    throw error.message
  }
}

export async function signUp(email, password) {
  try {
    return await firebase.auth().createUserWithEmailAndPassword(email, password)
  } catch (error) {
    throw error.message
  }
}

export async function signOut() {
  try {
    await firebase.auth().signOut()
  } catch (error) {
    throw error.message
  }
}

import { useState, useEffect, useCallback, memo, useRef } from 'react'
import { dateFormat } from 'utils/formatDate'
import { format } from 'date-fns'
import { getOrders } from 'api/orders'

export const PageTypes = {
  first: 'first',
  middle: 'middle',
  last: 'last',
}

const ROWS_PER_PAGE = 20

function throwEmptyPropError(propName) {
  throw `Couldn't find ${propName}`
}

const dayInMs = 3600 * 1000 * 24

const get3DaysLeftDate = () => {
  const currentDay = new Date(new Date(Date.now()).setHours(0, 0, 0, 0))
  let daysToAdd = 0
  switch (currentDay.getDay()) {
    case 0:
      daysToAdd = 4
      break //SUN MON TUE WED
    case 1:
    case 2:
    case 3:
      daysToAdd = 3
      break // JUST 3 WORKING DAYS
    case 4:
      daysToAdd = 5
      break // THU FRI SAT SUN MON
    case 5:
      daysToAdd = 4
      break // FRI SAT SUN MON TUE
    case 6:
      daysToAdd = 4
      break // SUN MON TUE WED
  }

  return format(new Date(Date.now() + daysToAdd * dayInMs), dateFormat)
}

export const formatParameters = (
  collectionName,
  { companyName, orderId, deadlineType, skip },
) => {
  const res = {}
  if (companyName) {
    res.companyName = companyName
  }

  if (orderId) {
    res.orderId = orderId
  }

  if (deadlineType !== 'all' && deadlineType) {
    const todaysDate = new Date(Date.now()).setHours(0, 0, 0, 0)
    const currentDate = format(todaysDate, dateFormat)

    switch (deadlineType) {
      default:
        break
      case 'deadline':
        res.sinceDeadline = currentDate
        res.toDeadline = currentDate
        break
      case 'afterDeadline':
        const yesterday = new Date(Date.now() - dayInMs).setHours(0, 0, 0, 0)
        const formattedYesterday = format(yesterday, dateFormat)
        res.toDeadline = formattedYesterday
        break
      case '3daysleft':
        res.toDeadline = get3DaysLeftDate()
        res.sinceDeadline = currentDate
        break
      case 'tomorrow':
        const tomorrowDate = new Date(Date.now() + dayInMs).setHours(0, 0, 0, 0)
        const formattedTomorrow = format(tomorrowDate, dateFormat)
        res.sinceDeadline = currentDate
        res.toDeadline = formattedTomorrow
    }
  }

  if (skip) {
    res.skip = skip
  }

  res.limit = ROWS_PER_PAGE
  res.collection = collectionName
  return res
}

export const useCustomPagination = (
  collectionName = throwEmptyPropError('collection name'),
  filters = {},
) => {
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [activePageType, setActivePageType] = useState(PageTypes.first)
  const cancellationController = useRef(null)

  const currentSkip = useRef(0)

  const cleanup = () => {
    if (cancellationController.current !== null) {
      cancellationController.current?.abort()
    }
  }
  const overrideCurrentData = (pages = [], direction = 'next') => {
    if (pages.length < ROWS_PER_PAGE) {
      switch (direction) {
        default:
        case 'next': {
          currentSkip.current -= pages.length
          setActivePageType(PageTypes.last)
          break
        }
        case 'prev': {
          currentSkip.current = 0
          setActivePageType(PageTypes.first)
        }
      }
    }

    if (currentSkip.current === 0) {
      setActivePageType(PageTypes.first)
    }

    setData(pages)
  }

  const initialFetch = async () => {
    try {
      setLoading(true)
      const controller = new AbortController()
      cancellationController.current = controller

      const data = await getOrders(
        formatParameters(collectionName, filters),
        controller,
      )

      currentSkip.current = data.length
      overrideCurrentData(data)
      setActivePageType(PageTypes.first)
      isLoading && setLoading(false)
    } catch (err) {
      console.log('Init fetch err')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    initialFetch()
    return cleanup
  }, [filters])

  const nextPageHandler = async () => {
    try {
      setLoading(true)
      const controller = new AbortController()
      cancellationController.current = controller

      const data = await getOrders(
        formatParameters(collectionName, {
          ...filters,
          skip: currentSkip.current,
        }),
        controller,
      )

      if (data.length) {
        currentSkip.current += data.length
        setActivePageType(PageTypes.middle)
        overrideCurrentData(data)
      } else {
        setActivePageType(PageTypes.last)
      }
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const previousPageHandler = async () => {
    try {
      setLoading(true)
      const controller = new AbortController()
      cancellationController.current = controller
      currentSkip.current = currentSkip.current - data.length - ROWS_PER_PAGE
      if (currentSkip.current < 0) {
        currentSkip.current = 0
        setActivePageType(PageTypes.first)
      }
      const newData = await getOrders(
        formatParameters(collectionName, {
          ...filters,
          skip: currentSkip.current,
        }),
        controller,
      )

      if (newData.length) {
        setActivePageType(PageTypes.middle)
        overrideCurrentData(newData, 'prev')
      } else {
        setActivePageType(PageTypes.first)
      }
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const goToBeginning = useCallback(() => {
    initialFetch()
  }, [])

  const goToPrev = useCallback(() => {
    previousPageHandler()
  }, [data])

  const goToNext = useCallback(() => {
    nextPageHandler()
  }, [])

  return {
    isLoading,
    activePageType,
    goToBeginning,
    goToPrev,
    goToNext,
    data,
  }
}

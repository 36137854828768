import { toast } from 'react-toastify'

export function downloadPDF(base64content, pdfName = 'dokument') {
  const linkSource = `data:application/pdf;base64,${base64content}`
  const downloadLink = document.createElement('a')
  const fileName = `${pdfName}.pdf`
  downloadLink.href = linkSource
  downloadLink.download = fileName
  downloadLink.click()
}

import React, { useEffect, useState } from 'react'
import {
  Col,
  Image,
  Row,
  Button,
  Modal,
  Pagination,
  ListGroup,
} from 'react-bootstrap'
import Switch from '../common/Switch'
import { signOut } from '../../api/auth'
import { isAdmin as isAdminAPI } from '../../api/roles'
import { getStatus, updateStatus } from '../../api/systemDisable'
import { SettingsIcon, LogoutIcon } from './icons'

import blesuLogo from '../../img/logoBlesu.png'
import * as classes from './Navbar.module.scss'
import SettingsModal from './SettingsModal'

export default () => {
  const [isAdmin, setIsAdmin] = useState(true)
  const [status, setStatus] = useState({})
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const delivertModes = { apaczka: 'apaczka', ups: 'ups' }

  useEffect(() => {
    checkRole()
    fetchStatus()
  }, [show])

  async function fetchStatus() {
    try {
      const status = await getStatus()
      setStatus(status)
    } catch (error) {
      console.error(error)
    }
  }

  async function checkRole() {
    try {
      const userIsAdmin = await isAdminAPI()
      setIsAdmin(userIsAdmin)
    } catch (error) {
      console.error(error)
    }
  }

  async function updateSystem() {
    try {
      await updateStatus(status)
    } catch (error) {
      console.log(error)
    }
    handleClose()
  }

  async function logout() {
    try {
      await signOut()
    } catch (error) {
      console.error(error)
    }
  }

  function getGeneratorStatus() {
    return (status.ups || status.apaczka) && status.invoices
      ? 'bg-success'
      : (status.ups || status.apaczka) ^ status.invoices
      ? 'bg-warning'
      : 'bg-danger'
  }

  //Switch delivery between UPS and Apaczka
  function onChangeDelivery(element) {
    if (element === delivertModes.apaczka)
      setStatus({ ...status, apaczka: true, ups: false })
    else setStatus({ ...status, apaczka: false, ups: true })
  }
  return (
    <Row className={classes.Container}>
      <SettingsModal show={show} handleClose={handleClose} />

      <Col bsPrefix="col d-flex align-items-center">
        <span className="mr-2">InvoWay by</span>
        <Image src={blesuLogo} width="120px" />
      </Col>
      <Col className={classes.Logout}>
        <div className="d-flex align-items-center mr-4">
          <div className={`${classes.Circle} ${getGeneratorStatus()} mr-4`} />
          {isAdmin && <SettingsIcon onClick={handleShow} />}
        </div>
        <LogoutIcon onClick={logout} />
      </Col>
    </Row>
  )
}

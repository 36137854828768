import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-toastify/dist/ReactToastify.css'

import App from './App'
import * as serviceWorker from './serviceWorker'
import firebase from 'firebase/app'
import { initFirebase } from './firebase'

initFirebase()
firebase.auth().onAuthStateChanged(async (user) => {
  ReactDOM.render(<App />, document.getElementById('root'))
})

serviceWorker.unregister()

import React, { memo, useState, useContext, useEffect } from 'react'
import clsx from 'clsx'

import { useFirestorePagination, PageTypes } from 'hooks/useFirebasePagination'
import { withOpenOrderAction } from 'hocs/withOpenOrderAction'

import classes from '../Results.module.scss'
import {
  DeleteIcon,
  DownloadIcon,
  ListIcon,
  PreviewIcon,
  WarningIcon,
} from '../Icons'
import { FiltersContext, anyFiltersSelected } from 'context/filtersContext'
import { useCustomPagination } from 'hooks/useCustomPagination'
import { parseISO } from 'date-fns'
import PDFPreviewModal from 'components/PDFPreviewModal'
import { downloadPDF } from 'utils/downloadPDF'
import { PortalButton } from 'components/common/DownloadListButton'
import { cancelWaybill, downloadInvoice } from 'api/orders'
import { toast } from 'react-toastify'
import { validateOrder } from 'utils/validateOrder'

const withFilters = (Component) => (props) => {
  const { filters } = useContext(FiltersContext)

  return <Component {...props} filters={filters} />
}

const combinedContextConsumers = (Component) =>
  withOpenOrderAction(withFilters(Component))

const WaybillRenderer = ({ waybill, docId }) => {
  const trackingNumber =
    waybill.UpsTrackingNumber ||
    waybill.ApaczkaTrackingNumber ||
    waybill.waybill_number ||
    waybill.packageId ||
    ''

  const label =
    trackingNumber ||
    (waybill.personalPickup === true && 'Odbiór osobisty') ||
    'Brak'

  const [pdfVal, setPdf] = useState(false)

  function openPDF() {
    if (waybill.waybillString) {
      setPdf(waybill.waybillString)
    }
  }

  function closePDF() {
    setPdf(false)
  }

  function downloadClick() {
    if (waybill.waybillString) {
      downloadPDF(waybill.waybillString, 'list_przewozowy')
    }
  }

  function openListPreview() {
    setPdf(waybill.checkListPdfBase64)
  }

  async function onCancelClick() {
    try {
      const { packageId, deliveryService } = waybill

      if (!(packageId && docId && deliveryService)) {
        throw 'Missing properties'
      }

      const res = await cancelWaybill(docId, deliveryService, packageId)

      toast('Anulowano list przewozowy')
    } catch (err) {
      toast('Nie udało się anulować listu przewozowego')
    }
  }

  return (
    <tr>
      <PDFPreviewModal pdfInBase64={pdfVal} onClose={closePDF} />
      <td>{label}</td>
      <td className={classes.SwitchCell}>
        {waybill.waybillString ? (
          <DownloadIcon onClick={downloadClick} />
        ) : null}
      </td>
      <td className={classes.SwitchCell}>
        {waybill.waybillString ? <PreviewIcon onClick={openPDF} /> : null}
      </td>
      <td className={classes.SwitchCell}>
        {waybill.checkListPdfBase64 ? (
          <ListIcon onClick={openListPreview} />
        ) : null}
      </td>
      <td className={classes.SwitchCell}>
        {waybill.status === 'ok' && waybill.deliveryService ? (
          <DeleteIcon onClick={onCancelClick} />
        ) : null}
      </td>
    </tr>
  )
}

const ErrorWaybill = ({}) => {
  return (
    <tr>
      <td>Błąd przy generowaniu listu przewozowego</td>
    </tr>
  )
}

const ExpandableRow = ({ openOrder = () => {}, data = {} }) => {
  const [opened, setOpened] = useState(false)
  const [openedInvoce, setOpenedInvoice] = useState(false)
  const {
    company_name: name = '',
    orderID,
    status = 'shipping',
    created_at = '',
    deadline = false,
    waybills = [],
    id = '',
  } = data

  function onClick() {
    openOrder(data, 'shippingOrders')
  }

  function onExpandClick(e) {
    e.stopPropagation()
    setOpened(!opened)
  }

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

  async function onDownloadClick(e) {
    e.stopPropagation()
    if (data.invoiceId) {
      try {
        const res = await downloadInvoice(data.invoiceId)
        const linkSource = `data:application/pdf;base64,${res.data}`
        const downloadLink = document.createElement('a')
        const fileName = `faktura.pdf`
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
        toast('Rozpoczęto pobieranie faktury')
      } catch (err) {
        console.log(err)
        toast('Nie udało się pobrać faktury')
      }
    }
  }

  function onPreviewClick(e) {
    e.stopPropagation()
    setOpenedInvoice(data.invoiceViewUrl)
  }

  function onPreviewClose() {
    setOpenedInvoice(false)
  }

  const { valid: isValid } = validateOrder(data)

  const filteredWaybills = waybills.filter((waybill) => !!waybill)

  return (
    <React.Fragment>
      <PDFPreviewModal pdfUrl={openedInvoce} onClose={onPreviewClose} />
      <tr
        className={clsx(opened && classes.RowActive)}
        style={{ cursor: 'pointer' }}
        onClick={onClick}
      >
        <td>
          <div
            className={
              opened ? classes.ExpandButtonActive : classes.ExpandButton
            }
            onClick={onExpandClick}
          ></div>
        </td>
        <td className={classes.AlertCell}>
          <span>{name}</span>
          {!isValid && <WarningIcon className={classes.AlertIcon} />}
        </td>
        <td>{orderID}</td>
        <td>{status}</td>
        <td>{created_at}</td>
        <td>
          {deadline
            ? new Date(parseISO(deadline)).toLocaleDateString()
            : 'No deadline'}
        </td>
        <td className={classes.SwitchCell}>
          <DownloadIcon onClick={onDownloadClick} />
        </td>

        <td className={classes.SwitchCell}>
          <PreviewIcon onClick={onPreviewClick} />
        </td>
      </tr>
      {opened ? (
        <tr className={classes.MoreInfo}>
          <td>
            <table className={classes.NestedTable}>
              <thead>
                <tr>
                  <th>Numer listu</th>
                  <th className={classes.SwitchCell}>Pobierz</th>
                  <th className={classes.SwitchCell}>Podgląd</th>
                  <th className={classes.SwitchCell}>Lista</th>
                  <th className={classes.SwitchCell}>Anuluj</th>
                </tr>
              </thead>
              <tbody>
                {filteredWaybills && filteredWaybills.length ? (
                  filteredWaybills.map((waybill) =>
                    waybill.status === 'ok' ? (
                      <WaybillRenderer
                        key={waybill.packageId || 'Personal_pickup_'}
                        waybill={waybill}
                        docId={id}
                      />
                    ) : (
                      <ErrorWaybill />
                    ),
                  )
                ) : (
                  <tr style={{ paddingTop: '15px' }}>
                    <td>Brak listów przewozowych</td>
                  </tr>
                )}
              </tbody>
            </table>
          </td>
        </tr>
      ) : null}
    </React.Fragment>
  )
}

const Renderer = ({ openOrder, pagination, hidePagination = false }) => {
  const {
    isLoading,
    activePageType,
    goToNext,
    goToPrev,
    goToBeginning,
    data = [],
  } = pagination

  const isFirstPage = activePageType === PageTypes.first
  const isLastPage = activePageType === PageTypes.last

  const isEmpty = !data.length

  return (
    <div className={classes.TableWrapper}>
      <PortalButton data={data} />
      {isLoading ? (
        <div className={classes.Loader}>
          <h5>Ładowanie</h5>
        </div>
      ) : null}
      <div
        style={{
          overflowY: 'scroll',
        }}
      >
        <table className={classes.ShippingOrdersTable}>
          <thead>
            <tr>
              <th></th>
              <th>Nazwa firmy</th>
              <th>ID</th>
              <th>Status</th>
              <th>Data utworzenia</th>
              <th>Deadline</th>
              <th className={classes.SwitchCell}>Pobierz</th>
              <th className={classes.SwitchCell}>Podgląd</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row) => (
              <ExpandableRow
                key={row.documentID}
                data={row}
                openOrder={openOrder}
              />
            ))}
          </tbody>
        </table>
      </div>
      {!hidePagination && (
        <div className={classes.Pagination}>
          <button disabled={isFirstPage || isEmpty} onClick={goToBeginning}>
            Najnowsze
          </button>
          <button disabled={isFirstPage || isEmpty} onClick={goToPrev}>
            Poprzednie
          </button>
          <button disabled={isLastPage || isEmpty} onClick={goToNext}>
            Następne
          </button>
        </div>
      )}
    </div>
  )
}

const FilteredRenderer = ({ filters, openOrder }) => {
  const pagination = useCustomPagination('shippingOrders', filters)

  return (
    <Renderer
      openOrder={openOrder}
      pagination={pagination}
      hidePagination={true}
    />
  )
}

const RealTimeRenderer = ({ filters, openOrder }) => {
  const pagination = useFirestorePagination('shippingOrders')

  return <Renderer openOrder={openOrder} pagination={pagination} />
}

const ShippingRenderer = memo(({ openOrder, filters }) => {
  const showFilteredList = anyFiltersSelected(filters)

  return showFilteredList ? (
    <FilteredRenderer filters={filters} openOrder={openOrder} />
  ) : (
    <RealTimeRenderer filters={filters} openOrder={openOrder} />
  )
})

export default combinedContextConsumers(ShippingRenderer)

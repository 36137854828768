import React from 'react'

import Main from './pages/Main'
import SignIn from './pages/Auth/Login'
import SignUp from './pages/Auth/SignUp'
import PrivateRoute from './components/PrivateRoute'
import UnauthenticatedRoute from './components/UnauthenticatedRoute'
import { Switch, BrowserRouter, Redirect } from 'react-router-dom'
import pl from 'date-fns/locale/pl'
import { registerLocale, setDefaultLocale } from 'react-datepicker'

import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import { ToastContainer } from 'react-toastify'
registerLocale('pl', pl)
setDefaultLocale('pl')
export default function () {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <PrivateRoute exact path={['/home', '/']} component={Main} />
          <UnauthenticatedRoute exact path="/sign-in" component={SignIn} />
          {/* <UnauthenticatedRoute exat path='/sign-up' component={SignUp} /> */}

          <Redirect to="/sign-in" />
        </Switch>
      </BrowserRouter>
      <ToastContainer />
    </>
  )
}

import React, { useState, useEffect } from 'react'
import PDFViewer from 'pdf-viewer-reactjs'
import pdfMode from '../../constants/pdfMode'

export default function ({ data, mode, url }) {
  if (url) {
    return (
      <iframe
        width={'100%'}
        height={'100%'}
        style={{ minHeight: '350px' }}
        src={url}
        title="pdf-viewer"
      />
    )
  }

  if (!data || !mode) return <h1 className="text-center mt-3">PDF Viewer</h1>

  if (data === 'Could not generate waybill' || data === 'odbiór osobisty') {
    return (
      <h5 className="text-center mt-3">
        Dla tego zamówienia nie wygenerowano listu przewozowego.
      </h5>
    )
  }

  if (data === 'Could not generate invoice' || data === '') {
    return (
      <h5 className="text-center mt-3">
        Dla tego zamówienia nie wygenerowano faktury.
      </h5>
    )
  }
  {
    /* */
  }

  // const [showingImage, setShowingImage] = useState(true)
  // useEffect(() => {
  //   setShowingImage(true)
  // }, [data])

  return (
    <iframe
      width={'100%'}
      height={'100%'}
      style={{ minHeight: '500px' }}
      src={`data:application/pdf;base64,${data}`}
      title="pdf-viewer"
    />
  )
  // return mode === pdfMode.base64 ? (
  //   showingImage ? (
  //     <img
  //       src={'data:image/png;base64,' + data}
  //       onError={() => setShowingImage(false)}
  //     />
  //   ) : (
  //     <PDFViewer document={{ base64: data }} />
  //   )
  // ) : (
  //   <iframe width={'100%'} height={'100%'} src={data} title="pdf-viewer" />
  // )
}

import { memo, useCallback, useState } from 'react'
import SectionHeader, { TabButton } from 'components/common/SectionHeader'
import classes from './Table.module.scss'
import Button from 'components/common/Button'
import Results from './Results'
import { InputIcon } from './InputIcon'
import { deadlineTypes, FiltersContext } from 'context/filtersContext'
import produce from 'immer'

export const Tabs = [
  { value: 'New', label: 'Nowe' },
  { value: 'Shipping', label: 'Shipping' },
]

const BadgeButton = ({
  active = false,
  onClick = () => {},
  value = '',
  children = '',
}) => (
  <button
    onClick={onClick}
    className={active ? classes.BadgeButtonActive : classes.BadgeButton}
  >
    {children}
  </button>
)

const badges = Object.values(deadlineTypes)

const Filters = memo(({ updateFilters, filters = {}, activeTab }) => {
  const { deadlineType = 'all' } = filters

  return (
    <div className={classes.Filters}>
      <div className={classes.Inputs}>
        <div className={classes.TextInputsWrapper}>
          <div className={classes.SearchInput}>
            <input
              type="text"
              placeholder="ID zamówienia..."
              value={filters.orderId || ''}
              onChange={(e) => {
                updateFilters({ orderId: e.target.value })
              }}
            />
            <InputIcon />
          </div>
          <div className={classes.SearchInput}>
            <input
              type="text"
              placeholder="Nazwa firmy..."
              value={filters.companyName || ''}
              onChange={(e) => {
                updateFilters({ companyName: e.target.value })
              }}
            />
            <InputIcon />
          </div>
        </div>

        <div className={classes.BadgesBox}>
          <span>Termin ważności</span>
          <div className={classes.BadgesWrapper}>
            {badges.map(({ value, text }) => (
              <BadgeButton
                key={`Badge_${value}`}
                active={value === deadlineType}
                onClick={() => {
                  updateFilters({ deadlineType: value })
                }}
              >
                {text}
              </BadgeButton>
            ))}
          </div>
        </div>
      </div>
      <div id="FILTERS_DOWNLOAD_LIST_BUTTON">{/* Check PortalButton */}</div>
    </div>
  )
})

const initValues = {
  orderId: '',
  companyName: '',
  deadlineType: 'all',
}

const Table = () => {
  const [activeTab, setActiveTab] = useState(Tabs[0].value)

  const [filters, setFilters] = useState(initValues)

  const updateFilters = useCallback(
    (newValues) => {
      setFilters(
        produce(filters, (draft) => {
          Object.keys(newValues).forEach((filterKey) => {
            draft[filterKey] = newValues[filterKey]
          })
        }),
      )
    },
    [filters],
  )

  const clearFilters = useCallback(() => {
    setFilters(initValues)
  }, [])

  return (
    <div className={classes.Table}>
      <FiltersContext.Provider value={{ filters, updateFilters }}>
        <SectionHeader>
          {Tabs.map(({ value, label }) => (
            <TabButton
              key={`TabButton_${value}`}
              active={activeTab === value}
              onClick={() => setActiveTab(value)}
            >
              {label}
            </TabButton>
          ))}
        </SectionHeader>
        <Filters
          updateFilters={updateFilters}
          filters={filters}
          activeTab={activeTab}
        />
        <Results tab={activeTab} />
      </FiltersContext.Provider>
    </div>
  )
}

export default memo(Table)

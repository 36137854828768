import { createContext } from 'react'

export const deadlineTypes = {
  ALL: { value: 'all', text: 'Wszystkie' },
  AFTER_DEADLINE: { value: 'afterDeadline', text: 'Po deadline' },
  DEADLINE: { value: 'deadline', text: 'Deadline' },
  DAYS_LEFT_3: { value: '3daysleft', text: '3 dni ważności' },
  TOMORROW: { value: 'tomorrow', text: 'Do jutra' },
}

export const FiltersContext = createContext({
  filters: { orderID: '', company_name: '', deadlineType: 'all' },
  updateFilters: (filters) => {},
  clearFilters: () => {},
})

export const anyFiltersSelected = (filters) =>
  Object.values(filters).some((val) => val !== '' && val !== 'all')

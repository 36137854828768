import React, { memo } from 'react'
import classes from './Results.module.scss'

import NewRenderer from './NewOrders'
import ShippingRenderer from './ShippingOrders'

const ErrorRenderer = () => <h1>Error</h1>

const getResultsRenderer = (tab) => {
  switch (tab) {
    case 'New':
      return NewRenderer
    case 'Shipping':
      return ShippingRenderer
    default:
      return ErrorRenderer
  }
}

const Results = memo(({ tab }) => {
  const ResultsRenderer = getResultsRenderer(tab)
  return (
    <div className={classes.ResultsWrapper}>
      <ResultsRenderer />
    </div>
  )
})

export default Results

import firebase from 'firebase';

export async function isAdmin() {
  try {
    const snapshot = await firebase.firestore().collection('roles').doc('admins').get();
    const adminsList = snapshot.data().adminsList;
    const uid = firebase.auth().currentUser.uid;
    return adminsList.some(adminId => adminId === uid);
  }
  catch (error) {
    throw (error.message)
  }
}
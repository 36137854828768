import * as classes from './SectionHeader.module.scss'

export const TabButton = ({ children, onClick, active, ...rest }) => (
  <button
    onClick={onClick}
    className={active ? classes.ActiveButton : classes.NormalButton}
    {...rest}
  >
    {children}
    <div></div>
  </button>
)

const SectionHeader = ({ children }) => (
  <div className={classes.Wrapper}>{children}</div>
)

export default SectionHeader

import clsx from 'clsx'
import { useState, useEffect, memo } from 'react'
import ReactSwitch from 'react-switch'
import * as classes from './Switch.module.scss'

const Switch = ({ checked = true, onClick = () => {}, ...rest }) => {
  const [test, setTest] = useState(checked)

  function onSwitchChange() {
    setTest(!test)
    onClick()
  }

  useEffect(() => {
    if (checked !== test) {
      setTest(checked)
    }
  }, [checked])
  return (
    <div style={{ padding: '4px' }}>
      <ReactSwitch
        onColor={'#367AF6'}
        offColor={'#FFFFFF'}
        onChange={onSwitchChange}
        checked={test}
        height={18}
        handleDiameter={18}
        boxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
        width={32}
        uncheckedIcon={false}
        checkedIcon={false}
        className={clsx(!test && classes.Active)}
        {...rest}
      />
    </div>
  )
}

export default Switch

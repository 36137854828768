import { getStatus, updateStatus } from 'api/systemDisable'
import firebase from 'firebase'
import { useEffect, useState } from 'react'
import { Modal, FormCheck, Form } from 'react-bootstrap'
import { toast } from 'react-toastify'
import Switch from '../common/Switch'

import * as classes from './Navbar.module.scss'
import RaportGenerator from './RaportGenerator'

const Inputs = ({
  waybillsRadioHandler,
  generateWaybillsSwitchHandler,
  generateInvocesSwitchHandler,
  settings = {},
  loading = false,
}) => {
  return (
    <div
      className={
        loading ? classes.LoadingContainerActive : classes.LoadingContainer
      }
    >
      <div className={classes.SwitchContainer}>
        <Switch
          onChange={generateInvocesSwitchHandler}
          checked={settings?.invoices}
        />
        <p> Generowanie faktur </p>
      </div>

      <div className={classes.SwitchContainer} style={{ marginTop: '20px' }}>
        <Switch
          onChange={generateWaybillsSwitchHandler}
          checked={settings?.ups || settings?.apaczka}
        />
        <p>Generowanie listów przewozowych</p>
      </div>

      <form className={classes.RadioForm}>
        <p>Listy przewozowe:</p>
        <Form.Check
          name="delivery"
          id="apaczka"
          type="radio"
          label="Apaczka"
          onChange={(e) => waybillsRadioHandler(e.target.checked, 'Apaczka')}
          checked={settings?.apaczka || false}
        />
        <Form.Check
          name="delivery"
          id="UPS"
          type="radio"
          label="UPS"
          onChange={(e) => waybillsRadioHandler(e.target.checked, 'UPS')}
          checked={settings?.ups || false}
        />
      </form>
    </div>
  )
}

const SettingsModal = ({ show, handleClose }) => {
  const [settings, setSettings] = useState(null)

  useEffect(() => {
    async function getCurrentSettings() {
      const res = await getStatus()
      setSettings(res)
    }

    getCurrentSettings()

    return () => {
      setSettings(null)
    }
  }, [show])

  async function setNewValues(values) {
    try {
      setSettings(values)
      await updateStatus(values)
      toast('Zaktualizowano ustawienia globalne')
    } catch (err) {
      toast('Nie udało się zaktualizować ustawień')
    }
  }

  async function generateInvocesSwitchHandler(checked) {
    setNewValues({ ...settings, invoices: checked })
  }

  async function generateWaybillsSwitchHandler(checked) {
    if (checked) {
      setNewValues({ ...settings, ups: true, apaczka: false })
    }

    if (!checked) {
      setNewValues({ ...settings, ups: false, apaczka: false })
    }
  }

  async function waybillsRadioHandler(checked, type) {
    switch (type) {
      case 'UPS':
        setNewValues({ ...settings, ups: checked, apaczka: !checked })
        break
      case 'Apaczka':
        setNewValues({ ...settings, apaczka: checked, ups: !checked })
    }
  }

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header bsPrefix={classes.ModalHeader} closeButton>
        <h4> Ustawienia </h4>
      </Modal.Header>

      <Modal.Body bsPrefix={classes.ModalBody}>
        <Inputs
          {...{
            generateWaybillsSwitchHandler,
            generateInvocesSwitchHandler,
            waybillsRadioHandler,
            loading: !settings,
            settings: settings,
          }}
        />
        <RaportGenerator />
      </Modal.Body>
    </Modal>
  )
}

export default SettingsModal
